import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Nicolas Marie Angélique</h1>
      <div className="introduction">
        <p>Bienvenue sur le site de Nicolas Marie-Angélique, étudiant en design à Bordeaux. Ce site a été créé pour offrir un accès temporaire à son travail. Vous y découvrirez son portfolio, son profil Dribbble, ainsi que ses réseaux sociaux professionnels. Profitez de cette opportunité pour explorer ses créations et son univers créatif.</p>
      </div>
      <ul className="word-list">
        <li><a href="https://dribbble.com/nicolasmarieangelique">Dribbble</a></li>
        <li><a href="https://www.dropbox.com/scl/fo/zkph6oaa18ahpurkk9sjh/h?rlkey=23kdsxgtknm0gu5ei0wf0xvi7&e=1&st=p5e02ufr&dl=0">Portfolio</a></li>
        <li><a href="https://www.linkedin.com/in/nicolas-marie-ang%C3%A9lique-3b0a58239">Linkedin</a></li>
        <li><a href="mailto:nmafreelance@gmail.com">Mail</a></li>
        <li><a href="https://www.instagram.com/nma_png/">Instagram</a></li>
      </ul>
    </div>
  );
}

export default App;
